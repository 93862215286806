.article-container {
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  border-radius: 8px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin: 16px;
  width: 350px;
  height: 350px;
  transition: opacity 1s ease, top 1s ease;
}

.article-container a {
  text-decoration: none;
}

.article-content h3 {
  margin: 30px;
  font-size: 1.8em;
  text-align: left;
  max-width: 250px;
  color: #3a6a50;
  line-height: 1.01;
  transition: font-size 1s ease, max-width 1s ease;
}

.article-container:hover {
  cursor: pointer;
}

.article-category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #3a6a50;
  padding: 10px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.article-content {
  padding: 0px 25px 40px 25px;
  text-align: center;
  height: 280px;
  width: 300px;
  color: #3a6a50;
  text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  top: 57%;
  background-color: #ffffff;
  transition: opacity 1s ease, top 1s ease;
}

.article-container:hover .article-content{
  top: 20%;
  padding: 40px 25px 40px 25px;
}

.article-container-content .article-hover-details {
  border-top: 1px solid #fff;
}

.article-container:hover .article-content h3 {
  max-width: 160px;
  font-size: 1.4em;
}

.article-content p {
  text-align: left;
  margin: auto;
  font-size: 0.8em;
  line-height: 1.01;
  color: #333333;
}