.loadingScreen {
    height: 100vh;
    width:100vw;
    background-color: #3a6a50;

    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
}

.logo {
    fill: #ffffff;
    height: 100px;
    width: auto;
}

.pg {
    font-family: Courier New;
}

