.sidebarItem{
  display: flex;  
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: solid 1px lightgray;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .sidebarItem {
      width: 180px;
      height: 100%;
      border-bottom: none;
      border-right: solid 1px lightgray;
  }
  .sidebarItem > div {
      width: 110px;
  }
}

.sidebarItem:hover{
  background-color: lightgray;
}

.sidebarItem > div {
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebarItem > div > .desc {
  margin-bottom: 0px ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebarItem > div > .subDesc {
  margin-top: 2px ;
  font-size: 12px;
  color: dimgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.heartBeat{
  animation: redBeat;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-timing-function: linear;
}

@keyframes redBeat {
  from {background-color: #ffffff;}
  50% {background-color: rgba(255,0,0,0.15)}
  to {background-color: #ffffff;}
}

.heartBeatReverse{
  animation: redBeatReverse;
  animation-direction: reverse;
  animation-duration: 1s;
  animation-iteration-count: 3;
  animation-timing-function: linear;
}

@keyframes redBeatReverse {
  from {background-color: #ffffff;}
  50% {background-color: rgba(255,0,0,0.15)}
  to {background-color: #ffffff;}
}
