
.content-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.content-container h1 {
  font-size: 2em;
  text-align: left;
  position: fixed;
  margin-bottom: 0;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
  margin-top: 85px;
  border-top: 1px solid #CAC4D0;
  overflow-y: auto;
  height: 75%;
}