.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 300px;
  max-width: 90%;
}

.hidden {
  opacity: 0;
}

.form h2 {
  margin-top: 0;
}

.form label {
  display: block;
  margin-bottom: 0.5rem;
}

.form input[type="text"],
.form input[type="password"],
.form select {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.form button[type="submit"] {
  display: block;
  width: 100%;
  background-color: #3a6a50;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.form button[type="submit"]:hover {
  background-color: #447f5b;
}