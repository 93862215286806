.default {
    fill: rgba(255,0,0,0.45);
}
.radiate {
    fill: rgba(255,0,0,0.45);
    stroke: #e86a73;
    animation-name: radiate-outward;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes radiate-outward {
    from {
        stroke-width: 0;
        stroke-opacity: 0.75;  
    }
    to {
        stroke-width: 200;
        stroke-opacity: 0;  
    }
}
