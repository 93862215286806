.static {
    fill: #86ae69; 
}

.dynamic {
    fill: #86ae69; 
    /*stroke: #e06c75;*/
    stroke: #e86a73;
    animation-name: radiate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes radiate {
    from {
        stroke-width: 0;
        stroke-opacity: 1;  
    }
    50% {
        stroke-opacity: 0.75;  
    }

    to {
        stroke-width: 300;
        stroke-opacity: 0;  
    }
}


.popUp {
    width: 350px;
}

.popUpBody {
    width: 310px;
}
.statusIndicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.streaming {
    background-color: #5cb85c;
    animation: blinkAnimation 1s infinite;
}

@keyframes blinkAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.not-streaming {
    background-color: #d9534f;
}

.not-linked {
    background-color: gray;
}

.realtimeGraphDiv:not(:empty) {
    background-color: #f4f4f4;
    padding: 5px;
    border-radius: 10px;
}