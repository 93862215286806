.sidebarInfo{
  box-sizing: border-box;
  padding: 15px;
  border-bottom: solid 1px lightgray;
  background-color: #f4f4f4;
}

h3 {
  margin: auto;
  color: #333333;
}

@media (max-width: 768px) {
  .sidebarInfo {
      width: 150px;
      height: 100%;
      border-bottom: none;
      border-right: solid 1px lightgray;
  }
}
